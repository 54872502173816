import React from 'react';
import { connectAppConnect } from "../AppConnect";

const Layout = ({ children }) => {
  connectAppConnect()
  return (
    <>
      {/* <script src="https://monapalette.komikikaku.com/script/monapaletteconnect.js"></script> */}
      <script src="https://cdn.kotaroo.work/AppConnect/monapaletteconnect.js"></script>
      <div className="font-normal" style={{ fontFamily: "Josefin Sans" }}>
      {/* <div className="font-sans"> */}
        {children}
      </div>
    </>
  );
};

export default Layout;
